import { api } from './api';

export const useFetchData = <TData, TVariables>(
  query: string
): (() => Promise<TData>) => {
  // it is safe to call React Hooks here.
  return async (variables?: TVariables) => {
    const {
      data: { data },
    } = await api({
      method: 'POST',
      url: '/graphql',
      data: {
        query,
        variables,
      },
    });

    return data;
  };
};

export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) => {
  return async () => {
    const { data } = await api({
      method: 'POST',
      url: '/graphql',
      data: {
        query,
        variables,
      },
    });

    if (data.errors) {
      console.log(data.errors[0].message);
      throw new Error(data.errors[0].message);
    }

    return data.data;
  };
};
